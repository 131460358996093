<template>
  <div>
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      @ok.prevent="onReturn"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Tout rendre"
      :ok-disabled="!isValid()"
    >
      <template v-slot:modal-title>
        <b>
          <i class="fa fa-rotate-left"></i>
          <span v-if="entity">{{ entity.name }}</span>
          - Rendre
        </b>
      </template>
      <b-row v-if="errors.length">
        <b-col>
          <div><b>Une erreur a eu lieu. Les retours ci-dessous n'ont pas été enregistrés</b></div>
          <div class="error-text" v-html="errorsText"></div>
        </b-col>
      </b-row>
      <b-row>
        <table class="table table-striped">
          <tr>
            <th>Jeu</th>
            <th>Référence</th>
            <th>Emprunté le</th>
            <th>Attendu le</th>
            <th v-if="hasPenalties">Pénalités</th>
            <th>Rendu le</th>
          </tr>
          <tr v-for="borrowing of borrowingsList" :key="borrowing.id">
            <td>{{ borrowing.article.name }}</td>
            <td>{{ borrowing.item.reference }}</td>
            <td>{{ borrowing.borrowedOn | dateToString }}</td>
            <td>{{ borrowing.returnExpectedOn | dateToString }}</td>
            <td v-if="hasPenalties">
              <div v-if="borrowing.hasPenalties()">
                <span class="badge badge-danger">pénalités</span>
                {{ borrowing.penaltiesOn | dateToString }}
                <div>
                  <decimal-input
                    :id="'penalties' + borrowing.id"
                    v-model="borrowing.penaltiesAmount"
                  >
                  </decimal-input>
                </div>
              </div>
            </td>
            <td>
              <b-form-input
                :id="'returnedOn' + borrowing.id"
                type="date"
                v-model="borrowing.returnedOn"
              ></b-form-input>
            </td>
          </tr>
        </table>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { mapActions } from 'vuex'
import DecimalInput from '@/components/Controls/DecimalInput.vue'

export default {
  name: 'ReturnAllBorrowModal',
  mixins: [BackendMixin],
  components: {
    DecimalInput,
  },
  props: {
    entity: Object,
    borrowings: Array,
    modalId: String,

  },
  data() {
    return {
      borrowingsList: [],
      errors: [],
      hasPenalties: false,
    }
  },
  computed: {
    errorsText() {
      return this.errors.join('<br />')
    },
  },
  watch: {
    item() {
      this.init()
    },
    borrowings() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions(['addSuccess']),
    init() {
      const today = moment().format('YYYY-MM-DD')
      this.hasPenalties = false
      this.borrowingsList = [].concat(this.borrowings)
      for (const borrowing of this.borrowings) {
        borrowing.returnedOn = today
        if (borrowing.hasPenalties()) {
          this.hasPenalties = true
        }
      }
    },
    isValid() {
      return true
    },
    async onReturn() {
      this.errors = []
      const errorBorrowings = []
      for (let borrowing of this.borrowingsList) {
        if (borrowing.returnedOn) {
          let url = '/store/api/return-borrow/'
          const backendApi = new BackendApi('post', url)
          const data = {
            item: borrowing.item.id,
            penalties_amount: borrowing.penaltiesAmount,
            returned_on: moment(borrowing.returnedOn).format('YYYY-MM-DD'),
          }
          try {
            const resp = await backendApi.callApi(data)
          } catch (err) {
            const ref = borrowing.article.name + ' ' + borrowing.item.reference
            errorBorrowings.push(borrowing)
            this.errors.push(ref + ': ' + this.getErrorText(err))
          }
        }
      }
      this.$emit('done')
      this.borrowingsList = errorBorrowings
      if (this.errors.length === 0) {
        await this.addSuccess('Le retour a été enregistré')
        this.$bvModal.hide(this.modalId)
      }
    },
  },
}
</script>

<style scoped>
</style>
