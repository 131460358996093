<template>
  <span>
    <span v-if="depositCheck" class="deposit-check">
      {{ checkInfo }}
      <span v-if="canReturn">
        <a class="btn btn-xs btn-secondary" href @click.prevent="returnCheck()">
          <i class="fa fa-rotate-left"></i> Rendre
        </a>
      </span>
    </span>
    <confirm-modal
       v-if="depositCheck && canReturn"
       name="return-deposit-check"
       title="Rendre lun chèque de caution"
       icon="fa fa-rotate-left"
      :text="returnCheckConfirmText"
      @confirmed="confirmReturnCheck()"
    ></confirm-modal>
  </span>
</template>

<script>
import { mapActions } from 'vuex'
import ConfirmModal from '@/components/Modals/ConfirmModal.vue'
import { currency, dateToString } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'

export default {
  name: 'StoreDepositCheckBadge',
  mixins: [BackendMixin],
  components: {
    ConfirmModal,
  },
  props: {
    depositCheck: Object,
  },
  data() {
    return {
      loadingName: 'returnStoreDepositCheck',
    }
  },
  computed: {
    canReturn() {
      return this.hasPerm('store.change_storedepositcheck')
    },
    checkInfo() {
      return this.getCheckInfo()
    },
    returnCheckConfirmText() {
      return this.getCheckInfo('Rendre le chèque')
    },
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    getCheckInfo(prefix = 'Chèque de caution') {
      let text = prefix
      if (this.depositCheck.bank) {
        text += ' ' + this.depositCheck.bank
      }
      if (this.depositCheck.checkNumber) {
        text += ' ' + this.depositCheck.checkNumber
      }
      if (this.depositCheck.date) {
        text += ' du ' + dateToString(this.depositCheck.date, 'DD/MM/YYYY')
      }
      if (this.depositCheck.checkAmount) {
        text += ' de ' + currency(this.depositCheck.checkAmount)
      }
      return text
    },
    returnCheck() {
      this.$bvModal.show('bv-confirm-modal:return-deposit-check')
    },
    async confirmReturnCheck() {
      this.startLoading(this.loadingName)
      try {
        let url = '/store/api/return-deposit-check/' + this.depositCheck.id + '/'
        let backendApi = new BackendApi('post', url)
        let resp = await backendApi.callApi({})
        this.$emit('done', {})
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
  },
}
</script>

<style scoped>
.deposit-check {
  font-size: 12px;
  padding: 2px;
  margin: 0 2px;
  border: solid 1px #ccc;
  border-radius: 4px;
  background: #f0f0cc;
  display: inline-block;
  vertical-align: middle;
}
</style>
