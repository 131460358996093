<template>
  <span v-if="entity && canAdd" style="margin: 0 2px;">
    <a href @click.prevent="showModal" class="btn btn-danger btn-sm">
      <i class="fa fa-money-bill-transfer"></i> {{ text }}
    </a>
    <b-modal
      dialog-class="modal-lg"
      id="addDepositCheckModal"
      @ok.prevent="onSave"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Ajouter"
      :ok-disabled="!date || !checkAmount"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-money-bill-transfer"></i> Ajout d'un chèque de caution</b>
      </template>
      <b-row>
        <b-col>
          <b-form-group label="Date*" label-for="date">
            <b-form-input type="date" id="date" v-model="date" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Montant*" label-for="checkAmount">
            <decimal-input id="checkAmount" v-model="checkAmount" required/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Banque" label-for="bank">
            <b-form-input type="text" id="bank" v-model="bank"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="N°" label-for="checkNumber">
            <b-form-input type="text" id="checkNumber" v-model="checkNumber"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import DecimalInput from '@/components/Controls/DecimalInput.vue'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'

export default {
  name: 'AddStoreDepositCheck',
  mixins: [BackendMixin],
  components: {
    DecimalInput,
  },
  props: {
    entity: Object,
    amount: Number,
    text: {
      type: String,
      default: 'Chèque de caution',
    },
  },
  data() {
    return {
      loadingName: 'addStoreDepositCheck',
      date: '',
      checkAmount: 0,
      bank: '',
      checkNumber: '',
    }
  },
  computed: {
    canAdd() {
      return this.hasPerm('store.add_storedepositcheck')
    },
  },
  watch: {
    amount: function() {
      this.checkAmount = this.amount
    },
  },
  mounted() {
    this.date = moment().format('YYYY-MM-DD')
    this.checkAmount = this.amount
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    showModal() {
      this.$bvModal.show('addDepositCheckModal')
    },
    async onSave() {
      this.startLoading(this.loadingName)
      try {
        let url = '/store/api/new-deposit-check/' + this.entity.id + '/'
        const data = {
          date: this.date,
          check_amount: this.checkAmount,
          bank: this.bank,
          check_number: this.checkNumber,
        }
        let backendApi = new BackendApi('post', url)
        await backendApi.callApi(data)
        this.$emit('done', {})
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
  },
}
</script>

<style scoped>
</style>
