
<template>
  <div>
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      @ok.prevent="onCreate"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Créer"
      :ok-disabled="!isValid()"
      @shown="init()"
      v-if="entity"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-dice"></i> Nouvelle adhésion</b>
      </template>
      <b-row v-if="error">
        <b-col>
          <div class="error-text">{{ error }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="entity-group"
            label="Famille"
            label-for="entity"
          >
            <b-form-input
              id="entity"
              type="text"
              disabled
              :value="entity.name + ': ' + entity.members"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="adhesionType-group"
            label="Type d'adhésion"
            label-for="adhesionType"
          >
            <b-select v-model="selectedAdhesionType" id="adhesionType">
              <b-select-option
                v-for="adhesionType of adhesionTypes"
                :key="adhesionType.id"
                :value="adhesionType"
              >
                {{ adhesionType.name }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="price-group"
            label="Prix"
            label-for="price"
          >
            <decimal-input v-model="price" id="price"></decimal-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="analyticAccount-group"
            label="Compte analytique"
            label-for="analyticAccount"
          >
            <b-select v-model="selectedAnalyticAccount" id="analyticAccount" disabled>
              <b-select-option
                v-for="analyticAccount of analyticAccounts"
                :key="analyticAccount.id"
                :value="analyticAccount"
              >
                {{ analyticAccount.name }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makeStoreAdhesion, makeStoreAdhesionType } from '@/types/store'
import { mapActions } from 'vuex'
import { makeAnalyticAccount } from '@/types/payments'
import DecimalInput from '@/components/Controls/DecimalInput.vue'

export default {
  name: 'StoreAdhesionModal',
  mixins: [BackendMixin],
  components: {
    DecimalInput,
  },
  props: {
    entity: Object,
    modalId: String,
  },
  data() {
    return {
      price: 0,
      selectedAdhesionType: null,
      selectedAnalyticAccount: null,
      adhesionTypes: [],
      analyticAccounts: [],
      error: '',
      initialized: false,
    }
  },
  computed: {
  },
  watch: {
    selectedAdhesionType() {
      if (this.selectedAdhesionType) {
        this.price = this.selectedAdhesionType.price
        let index = this.analyticAccounts.map(elt => elt.id).indexOf(this.selectedAdhesionType.analyticAccount)
        if (index < 0) {
          index = 0
        }
        if (this.analyticAccounts.length) {
          this.selectedAnalyticAccount = this.analyticAccounts[index]
        }
      }
    },
  },
  async mounted() {
  },
  methods: {
    ...mapActions(['addSuccess']),
    async init() {
      if (!this.initialized) {
        await this.loadAnalyticAccounts()
        await this.loadAdhesionTypes()
        this.initialized = true
      }
    },
    isValid() {
      return this.entity && this.selectedAdhesionType
    },
    async loadAnalyticAccounts() {
      let url = '/api/analytic-accounts/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.analyticAccounts = [makeAnalyticAccount()].concat(
          resp.data.map(
            elt => makeAnalyticAccount(elt)
          )
        )
      } catch (err) {
        this.error = this.getErrorText(err)
      }
    },
    async loadAdhesionTypes() {
      let url = '/store/api/store-adhesion-types/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.adhesionTypes = resp.data.map(
          elt => makeStoreAdhesionType(elt)
        )
      } catch (err) {
        this.error = this.getErrorText(err)
      }
    },
    async onCreate() {
      this.error = ''
      try {
        let url = '/store/api/adhesions/'
        const backendApi = new BackendApi('post', url)
        const data = {
          adhesion_type: this.selectedAdhesionType.id,
          entity: this.entity.id,
          price: this.price,
          // analytic_account: this.selectedAnalyticAccount ? this.selectedAnalyticAccount.id : 0,
        }
        const resp = await backendApi.callApi(data)
        const adhesion = await makeStoreAdhesion(resp.data)
        await this.addSuccess('L\'adhésion a été enregistré')
        this.$emit('done', adhesion)
        this.$bvModal.hide(this.modalId)
      } catch (err) {
        this.error = this.getErrorText(err)
      }
    },

  },
}
</script>

<style scoped>
.select-family {
  padding: 2px 5px;
  background: #f8b200;
  font-weight: bold;
}
.family-selected {
  padding: 2px 5px;
  background: #f2f2a2;
  font-weight: bold;
}
</style>
